// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div>
    <!-- License Detail Table -->
    <a-modal
      :title="$t('label.licence.details')"
      v-model:visible="openLicenseDetailsModal"
      :closable="true"
      width="100%"
      @cancel="openLicenseDetailsModal = false"
      centered
    >
      <template #footer>
        <a-button key="submit" type="primary" @click="openLicenseDetailsModal = false">
          Ok
        </a-button>
      </template>
      <!-- could be handled with multiple pages instead of scrolling -->
      <div style="overflow-y:scroll; height: 78vh;">
        <a-table :columns="columns" rowKey="vmName" :data-source="licenseDetailsInput" :pagination="false">
          <template #customTitle>
            <a-tag>{{ 'text' }}</a-tag>
          </template>
          <template
            #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
            style="padding: 8px"
          >
            <a-input
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              v-model:value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              Search
            </a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
              Reset
            </a-button>
          </template>
          <template #filterIcon="{ filtered }">
            <SearchOutlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
          </template>
          <template #customRender="{ text, column }">
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </a-table>
      </div>
    </a-modal>
    <a-spin :spinning="loading" tip="Loading..." style="position: fixed; right: 43%; top: 50%"></a-spin>
    <a-spin :spinning="changeAccountDisplayLoading" tip="Loading...">
      <div class="globalContainer" v-if="dataLoaded">
        <a-card>
          <div class='card-grey' style="width: 100%;">
            <div class="quotaStatementContainer">
            <!-- Selection with all SubAccounts (Customers) if the logged in user is a ResellerAdmin -->
            <div v-if="this.$store.getters.userInfo.rolename === 'ResellerAdmin'" class="accountDisplaySelection">
              <span>{{ $t('label.select.account.reseller.dashboard') }}</span>
              <a-select
                :disabled="subAccounts === []"
                style="width: 95%;"
                :placeholder="$t('label.select.account')"
                @change="accountSelected"
                :defaultValue="accountName">
                <a-select-option :value="accountName">
                  <span>
                    {{ accountName }}
                  </span>
                </a-select-option>
                <a-select-option v-for="account in subAccounts" :key="account.accountName">
                  <span>
                    {{ account.accountName }}
                  </span>
                </a-select-option>
              </a-select>
            </div>
            <div class="quotaStatement">
              <span style="font-size: calc(13px + 0.6vw);">{{ $t('label.incurring.costs') + '0' + getFirstDay + '.' + getMonth + '. - ' + getCurrentDate + ' \u2014 ' }}</span>
              <span class="loadingAnimation" style="font-weight: bold; font-size: calc(13px + 0.6vw);" v-if="currentQuota.length === 0">{{ $t('label.cost.calculation') }}</span>
              <span v-else style="font-weight: bold; font-size: calc(13px + 0.6vw);">{{ ' ' + currentQuota.toFixed(2)+ ' €' }}</span>
              <div style="display: flex; flex-direction: row; margin-bottom: 20px;">
                <a-progress style="width: 80%;" type="line" strokeColor="#FFA131" :showInfo="false" :percent="getPastDaysAsPercent"/>
                <span style="margin-left: calc(10px + 0.2vw); font-size: calc(10px + 0.2vw)">{{ getCurrentDay + ' / ' + getLastDay + $t('label.days') }}</span>
              </div>
            </div>
          </div>
          </div>
          <div class="metricsContainer">
            <!-- List that displays the overall ressources of a current account -->
            <a-menu class="resourceList" v-if="metricsLoaded && isStatsEmpty === false">
              <a-menu-item class="resourceListLink" key="1">
                <template #icon>
                  <DesktopOutlined :style="{ fontSize: '20px', color: stats[0].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[0].name + ' \u2014 ' }}{{ stats[0].count == undefined ? 0 : stats[0].count }}</span>
                <router-link v-if="stats[0] !== undefined" :to="{path: stats[0].path, query:  stats[0].query }"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="2">
                <template #icon>
                  <PoweroffOutlined :style="{ fontSize: '20px', color: stats[1].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[1].name + ' \u2014 ' }}{{ stats[1].count == undefined ? 0 : stats[1].count }}</span>
                <router-link v-if="stats[1] !== undefined" :to="{path: stats[1].path , query:  stats[1].query}"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="3">
                <template #icon>
                  <NumberOutlined :style="{ fontSize: '20px', color: stats[2].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[2].name + ' \u2014 ' }}{{ stats[2].count == undefined ? 0 : stats[2].count }}</span>
                <router-link v-if="stats[2] !== undefined" :to="{path: stats[2].path}"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="4">
                <template #icon>
                  <DatabaseOutlined :style="{ fontSize: '20px', color: stats[3].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[3].name + ' \u2014 ' }}{{ stats[3].count == undefined ? 0 : stats[3].count }}</span>
                <router-link v-if="stats[3] !== undefined" :to="{path: stats[3].path}"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="5">
                <template #icon>
                  <ApartmentOutlined :style="{ fontSize: '20px', color: stats[4].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[4].name + ' \u2014 ' }}{{ stats[4].count == undefined ? 0 : stats[4].count }}</span>
                <router-link v-if="stats[4] !== undefined" :to="{path: stats[4].path}"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="6">
                <template #icon>
                  <EnvironmentOutlined :style="{ fontSize: '20px', color: stats[5].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[5].name + ' \u2014 ' }}{{ stats[5].count == undefined ? 0 : stats[5].count }}</span>
                <router-link v-if="stats[5] !== undefined" :to="{path: stats[5].path}"></router-link>
              </a-menu-item>
              <a-menu-item class="resourceListLink" key="7">
                <template #icon>
                  <DeploymentUnitOutlined :style="{ fontSize: '20px', color: stats[6].bgcolor }"/>
                </template>
                <span style="font-size: 15px;">{{ stats[6].name + ' \u2014 ' }}{{ stats[6].count == undefined ? 0 : stats[6].count }}</span>
                <router-link v-if="stats[6] !== undefined" :to="{path: stats[6].path}"></router-link>
              </a-menu-item>
            </a-menu>
            <table class="domainMetricsTable">
              <tr>
                <th colspan="6" style="text-align: center; width: 100%;">
                  <span style="font-size: 15px; font-weight: bold;">{{ $t('label.costoverview') }}
                    <a-tooltip placement="top">
                        <template #title>
                          {{ 'Zusammengefasste geschätzte monatliche Kosten für das aktuelle Setup aller Hardware-Komponenten. Die unten gelisteten Preise sind annäherungsweise für voll ausgelastete Resourcen berechnet und können von den tatsächlichen Kosten teilweise abweichen.' }}
                        </template>
                        <InfoCircleOutlined style="margin-left: 3px;color:#fff"/>
                      </a-tooltip>
                    <!-- <a-tag v-if="isCurrentSetupActive" color="green">{{ isCurrentSetupActive ? $t('label.current.setup') : $t('label.calculating') }}</a-tag>
                    <a-tag v-else color="red">{{ isCurrentSetupActive ? $t('label.current.setup') : $t('label.calculating') }}</a-tag> -->
                  </span>
                </th>
              </tr>
              <!-- CPU Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><QrcodeOutlined/>{{ ' vCPU' }}</td>
                <td class="usageAmountColumn">{{ (runningVMCPU + (totalDeploymentInfo.totalDeploymentCpus || 0)) + ' vCPUs' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'vCPU')[0].discount === 0">{{ roundValue(discountList.filter(x => x.usageName === 'vCPU')[0].quotaTariff) + ' € / vCPU' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'vCPU')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'vCPU')[0].discountCalculated) + '  € / vCPU (- ' + discountList.filter(x => x.usageName === 'vCPU')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(cpuCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Licences Row -->
              <tr id="licences" class="collapsable expand tableRow" @click="collapseRow('licences', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' ' + $t('label.licences') }}<a-button style="margin-left: 1rem;" @click.stop="openLicenseDetails()" size="small">{{ $t('label.details') }}</a-button></td>
                <td class="usageAmountColumn">{{ (totalSoftwareLicenseAmount + (windowsServer === '' ? 0 : windowsServer) + (windowsClients === '' ? 0 : windowsClients)) + ' ' + $t('label.licences') }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ (totalLicenseCostDiscounted).toFixed(2) + ' €' }}</td>
              </tr>
              <!-- Licence Details Row -->
              <tr id="licencesDetails" v-for="tag in groupedTagInfo" :key="tag.tagName">
                <td class="usageTypeColumn"><FileDoneOutlined style="margin-right: 5px;"/><a-tag :color="tag.tagColor">{{ tag.tagName }}</a-tag></td>
                <td class="usageAmountColumn" v-if="tag.tagType !== 'OS'">{{ tag.licenseCounter + ' ' + (tag.tagType === 'Software per User' ? 'User' : (tag.tagType === 'Software per Core' ? $t('label.metrics.num.cpu.cores') : (tag.tagType === 'Hardware' ? 'GPUs' : $t('label.licences')))) }} </td>
                <td class="usageAmountColumn" v-else-if="tag.tagType === 'OS'">{{ tag.licenseCounter + ' vCPUs' }}</td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="tag.discount === 0">{{ tag.quotaTariff.toFixed(2) + ' € / ' + (tag.tagType === 'Software per User' ? 'User' : (tag.tagType === 'Software per Core' ? $t('label.metrics.num.cpu.core') : (tag.tagType === 'OS' ? 'vCPU' : (tag.tagType === 'Hardware' ? 'GPUs' : $t('label.licence'))))) }}</td>
                <td class="tariffColumn" v-else><span class="strikeout">{{ tag.quotaTariff.toFixed(2) + ' €' }}</span><span>{{ '\t' + (tag.discountCalculated).toFixed(2) + '  € / '+ (tag.tagType === 'Software per User' ? 'User (- ' + tag.discount + '%)' : (tag.tagType === 'Software per Core' ? $t('label.metrics.num.cpu.core') + ' (- ' + tag.discount + '%)' : (tag.tagType === 'OS' ? 'vCPU (- ' + tag.discount + '%)' : $t('label.licence') + ' (- ' + tag.discount + '%)'))) }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn" >{{ (tag.licenseCounter * tag.discountCalculated).toFixed(2) + ' €' }}</td>
              </tr>

              <!-- <tr id="winServer" class="collapsable expand innerCollapseEnd" @click="collapseRow('winServer', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' Windows Server' }}</td>
                <td class="usageAmountColumn">{{ windowsServer + ' vCPUs' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') !== -1 && x.tagType === 'OS')[0].tagText))[0].discount === 0">{{ discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') !== -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff.toFixed(2) + ' € / vCPU' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout">{{ discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') !== -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff.toFixed(2) + ' €' }}</span><span style="color:green;">{{ '\t' + discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') !== -1 && x.tagType === 'OS')[0].tagText))[0].discountCalculated.toFixed(2) + '  € / vCPU (- ' + discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') !== -1 && x.tagType === 'OS')[0].tagText))[0].discount.toFixed(2) + '%)' }}</span></td>
                <td>=</td>
                <td class="singleCostColumn">{{ totalLicenseCostDiscounted.toFixed(2) + ' €' }}</td>
              </tr>
              <tr id="winServerDetails" v-for="item in serverVMInfo" :key="item[0]">
                <td class="usageTypeColumn"><a-icon type="desktop" style="margin-left: 35px;"></a-icon>{{ ' ' + item[0] }}</td>
                <td class="usageAmountColumn">{{ item[1] + ' vCPUs' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td>=</td>
                <td class="singleCostColumn">{{ (item[1] * item[2]).toFixed(2) + ' €' }}</td>
              </tr>
              <tr id="winClient" class="collapsable expand innerCollapseEnd" @click="collapseRow('winClient', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' Windows Client' }}</td>
                <td class="usageAmountColumn">{{ windowsClients + ' vCPUs' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].discount === 0">{{ discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff.toFixed(2) + ' € / vCPU' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout">{{ discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff.toFixed(2) + ' €' }}</span><span style="color:green;">{{ '\t' + (discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].discountCalculated).toFixed(2) + '  € / vCPU (- ' + discountList.filter(x => x.usageName === (allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].discount + '%)' }}</span></td>
                <td>=</td>
                <td class="singleCostColumn">{{ windowsClientCostDiscounted.toFixed(2) + ' €' }}</td>
              </tr>
              <tr id="winclientDetails" v-for="item in clientVMInfo" :key="item[0]">
                <td class="usageTypeColumn"><a-icon type="desktop" style="margin-left: 35px;"></a-icon>{{ ' ' + item[0] }}</td>
                <td class="usageAmountColumn">{{ item[1] + ' vCPUs' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td>=</td>
                <td class="singleCostColumn">{{ (item[1] * item[2]).toFixed(2) + ' €' }}</td>
              </tr> -->
              <!-- RAM Row -->
              <tr class="endCollapse tableRow">
                <td class="usageTypeColumn"><render-icon icon="save-outlined"></render-icon>{{ ' vRAM' }}</td>
                <td class="usageAmountColumn">{{ (runningVMRam + ((totalDeploymentInfo.totalDeploymentRam || 0) * 1024)) < 1024 ? (runningVMRam + (totalDeploymentInfo.totalDeploymentRam || 0)) + ' MB ' : ((runningVMRam + ((totalDeploymentInfo.totalDeploymentRam || 0) * 1024)) / 1024).toFixed(2) + ' GB ' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'MEMORY')[0].discount === 0">{{ (discountList.filter(x => x.usageName === 'MEMORY')[0].quotaTariff * 1024).toFixed(2) + ' € / GB' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ (discountList.filter(x => x.usageName === 'MEMORY')[0].quotaTariff * 1024).toFixed(2) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'MEMORY')[0].discountCalculated * 1024) + '  € / GB (- ' + discountList.filter(x => x.usageName === 'MEMORY')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(ramCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Primary Storage Row -->
              <tr id="primStorage" class="collapsable expand tableRow" @click="collapseRow('primStorage', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ $t('label.highperformance.storage') }}</td>
                <td class="usageAmountColumn">{{ parseFloat((hddData + (ssdData + (totalDeploymentInfo.totalDeploymentDisk || 0))).toFixed(2)) + ' GB' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(hddStorCostDiscounted + ssdStorCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- HDD Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="hdd-outlined" style="margin-left: 25px;"></render-icon>{{ ' HDD' }}</td>
                <td class="usageAmountColumn">{{ parseFloat(hddData.toFixed(2)) + ' GB' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'HDD')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'HDD')[0].quotaTariff.toFixed(2) + ' € / GB' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ discountList.filter(x => x.usageName === 'HDD')[0].quotaTariff.toFixed(2) + ' €' }}</span><span>{{ '\t' + (discountList.filter(x => x.usageName === 'HDD')[0].discountCalculated).toFixed(2) + '  € / GB (- ' + discountList.filter(x => x.usageName === 'HDD')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(hddStorCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- SSD Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="hdd-outlined" style="margin-left: 25px;"></render-icon>{{ ' SSD' }}</td>
                <td class="usageAmountColumn">{{ parseFloat((ssdData + (totalDeploymentInfo.totalDeploymentDisk || 0)).toFixed(2)) + ' GB' }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'SSD')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'SSD')[0].quotaTariff.toFixed(2) + ' € / GB' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ discountList.filter(x => x.usageName === 'SSD')[0].quotaTariff.toFixed(2) + ' €' }}</span><span>{{ '\t' + (discountList.filter(x => x.usageName === 'SSD')[0].discountCalculated).toFixed(2) + '  € / GB (- ' + discountList.filter(x => x.usageName === 'SSD')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(ssdStorCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Secondary Storage Row -->
              <tr id="secStorage" class="collapsable expand endCollapse tableRow" @click="collapseRow('secStorage', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' Snapshots + Templates' }}</td>
                <td class="usageAmountColumn">{{ parseFloat(templateData + isoData + snapshotData).toFixed(2) + ' GB' }}</td>
                <td class="xColumn">x</td>
                <!-- The value of sec. Storage will be the value of SNAPSHOT, because sec. Storage is composed of shnapshot,ISO and Template wich have all the same price. -->
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'SNAPSHOT')[0].quotaTariff + ' € / GB' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'SNAPSHOT')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discountCalculated.toFixed(2) + '  € / GB (- ' + discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(secStorCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Template Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><LaptopOutlined style="margin-left: 25px;"></LaptopOutlined>{{ ' Templates' }}</td>
                <td class="usageAmountColumn">{{ templateData === 0 ? 0 + ' GB' : parseFloat(parseInt(templateData).toFixed(2)) + ' GB' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(templateCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- ISO Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="usb-outlined" style="margin-left: 25px;"></render-icon>{{ ' Iso' }}</td>
                <td class="usageAmountColumn">{{ isoData === 0 ? 0 + ' GB' : parseFloat(isoData.toFixed(2)) + ' GB' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(isoCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Snapshot Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="camera-outlined" style="margin-left: 25px;"></render-icon>{{ ' Snapshots' }}</td>
                <td class="usageAmountColumn">{{ snapshotData === 0 ? 0 + ' GB' : parseFloat(snapshotData.toFixed(2)) + ' GB' }} </td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(snapshotCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- IP Row -->
              <tr class="endCollapse tableRow">
                <td class="usageTypeColumn"><render-icon icon="environment-outlined"></render-icon>{{ ' ' + $t('label.menu.ipaddresses') }}</td>
                <td class="usageAmountColumn">{{ allocatedIps }} </td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].quotaTariff + ' € / IP' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discountCalculated) + '  € / IP (- ' + discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(ipCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Cyber Security & Remote Backup Services Row -->
              <tr id="csrbs" class="collapsable expand endCollapse tableRow" @click="collapseRow('csrbs', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' Cyber Cloud Services' }}</td>
                <td class="usageAmountColumn"></td>
                <td class="xColumn"></td>
                <!-- The value of sec. Storage will be the value of SNAPSHOT, because sec. Storage is composed of shnapshot,ISO and Template wich have all the same price. -->
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(rbsCssCostDiscounted + rbsCostDiscounted + acronisMaintenanceCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Cyber Security Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="safety-outlined" style="margin-left: 25px;"></render-icon>{{ ' Cyber Cloud Agents' }}</td>
                <td class="usageAmountColumn">{{ acronisCssData + ' Agents' }}</td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'AcronisCSS')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'AcronisCSS')[0].quotaTariff + ' € / Agent' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'AcronisCSS')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'AcronisCSS')[0].discountCalculated) + '  € / VM (- ' + discountList.filter(x => x.usageName === 'AcronisCSS')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(rbsCssCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Backup Row -->
              <tr class="tableRow">
                <td class="usageTypeColumn"><render-icon icon="import-outlined" style="margin-left: 25px;"></render-icon>{{ ' Cyber Cloud Remote Backup' }}</td>
                <td class="usageAmountColumn">{{ acronisPbsData }}</td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'AcronisRBS')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'AcronisRBS')[0].quotaTariff + ' € / GB' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'AcronisRBS')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'AcronisRBS')[0].discountCalculated) + '  € / GB (- ' + discountList.filter(x => x.usageName === 'AcronisRBS')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(rbsCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Maintenance Row -->
              <tr class="tableRow" v-if="discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint') !== undefined">
                <td class="usageTypeColumn"><render-icon icon="tool-outlined" style="margin-left: 25px;"></render-icon>{{ ' ' + $t('label.maintenance') }}</td>
                <td class="usageAmountColumn">{{ acronisMaintenanceData }}</td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint') !== undefined ? discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0].quotaTariff + ' € / Maintenance' : 0 + ' € / Maintenance' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint') !== undefined ? discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0].quotaTariff : 0) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint') !== undefined ? discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0].discountCalculated : 0) + '  € / Maintenance (- ' + discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint') !== undefined ? discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0].discount : 0 + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(acronisMaintenanceCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- Next Generation Firewall Row -->
              <tr class="endCollapse tableRow" v-if="isAnyNgfActive">
                <td class="usageTypeColumn"><render-icon icon="fire-outlined"></render-icon>{{ ' Next-Generation-Firewall' }}</td>
                <td class="usageAmountColumn">{{ enabledNgfs }}</td>
                <td class="xColumn">x</td>
                <td class="tariffColumn" v-if="discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0].discount === 0">{{ discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0].quotaTariff + ' € / NGF' }}</td>
                <td class="tariffColumn" v-else><span class="strikeout rowDiscount">{{ roundValue(discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0].quotaTariff) + ' €' }}</span><span>{{ '\t' + roundValue(discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0].discountCalculated) + '  € / NGF (- ' + discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0].discount + '%)' }}</span></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ roundValue(ngfCostDiscounted) + ' €' }}</td>
              </tr>
              <!-- NoBill Row -->
              <tr id="noBill" class="tableRow collapsable expand" v-if="true" @click="collapseRow('noBill', 400)">
                <td class="usageTypeColumn"><span class="sign"></span>{{ ' Not billed VMs' }}</td>
                <td class="usageAmountColumn"></td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ 0 + ' €' }}</td>
              </tr>
              <tr class="tableRow" v-for="vm in noBillVmList" :key="vm.id">
                <td class="usageTypeColumn"><render-icon icon="desktop-outlined"></render-icon>{{ ' ' + vm.name }}</td>
                <td class="usageAmountColumn">{{ vm.cpunumber + ' vCPUs | ' + (vm.memory > 1000 ? (vm.memory / 1024) + ' GB' : vm.memory + ' MB') + ' RAM | ' + (vm.ssd !== undefined ? (vm.ssd + ' GB SSD | ') : '') + (vm.hdd !== undefined ? vm.hdd + ' GB SSD' : '') }}</td>
                <td class="xColumn"></td>
                <td class="tariffColumn"></td>
                <td class="equalColumn">=</td>
                <td class="singleCostColumn">{{ 0 + ' €' }}</td>
              </tr>
              <tr class="endCollapse">
                <!-- if there is a discount on only one resource Type. show the Discounted Total Value. Otherwise show only the Total value without any discount. -->
                <td
                  v-if="discountList.filter(x => x.usageName === 'vCPU')[0].discount !== 0
                    | discountList.filter(x => x.usageName === 'MEMORY')[0].discount !== 0
                    | discountList.filter(x => x.usageName === 'VOLUME')[0].discount !== 0
                    | discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discount !== 0
                    | discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discount !== 0"
                  colspan="6"
                  style="text-align: right; font-weight: bold;">{{ $t('label.monthly.total') }}<span class="strikeout">{{ totalCost.toFixed(2) + ' €' }}</span><span>{{ ' ' + roundValue(totalCostDiscounted) + ' €' }}</span></td>
                <td v-else colspan="6" style="text-align: right; font-weight: bold;">{{ $t('label.monthly.total') + totalCost.toFixed(2) + ' €' }}</td>
              </tr>
            </table>
          </div>
          <ApexChart
            v-if="billingList.length > 0"
            :billingList="billingList"
            :finalChartCostSummary="finalChartCostSummary"
            :accountUUID="accountIdInput"
            :domainUUID="domainIdInput"
            @emit-reload-final-chart-data="getOnlyMonthlySummary"/>
          <!-- costCalculator -->
          <!-- <div class="costCalculator">
            <div style="text-align: center; background-color: #3D7CAF; padding-top: 12px; padding-bottom: 12px; color: white; font-weight: bold;">
              <span style="font-size: 15px;">{{ $t('label.cost.calculator') + ' ' }}</span>
              <a-tooltip :title="'Sie benötigen mehr Resourcen? Einfach die gewünschten Mengen im Kostenrechner eingeben und Ihr monatlicher Preis wird in der Kostenübersicht ermittelt.'">
                <a-icon type="info-circle" style="color: white" />
              </a-tooltip>
            </div>
            CPU Slider + 3 Input Fields (CPU and Windows Licences)
            <div style="display: flex; flex-direction: column; margin-top: 10px;">
              <span style="padding-left: 35px;"><a-icon type="qrcode"></a-icon>{{ ' vCPU' }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  class="cpuSlider"
                  :min="minCpu"
                  :max="maxCpu"
                  v-model.number="runningVMCPU"
                  @change="validateInput('cpu')"/>
                <a-input
                  class="inputField"
                  id="cpu"
                  style="min-width: 8em;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model.number="runningVMCPU"
                  :maxLength="3"
                  :min="minCpu"
                  :max="maxCpu"
                  addonAfter="CPUs"
                  required
                  @change="validateInput('cpu')"/>
                <div class="licenceInputWrapper">
                  <div style="margin-top: -23px; text-align: center;">
                    <h5 style="margin-left: 10px;">Windows Server
                      <a-tooltip :title="'Anteil der Windows Server Lizenzen an der gesamt CPU Anzahl'">
                        <a-icon type="info-circle" style="color: black;" />
                      </a-tooltip>
                    </h5>
                    <a-input
                      class="serverInputField"
                      id="server"
                      style="margin-left: 10px;"
                      oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                      v-model.number="windowsServer"
                      :maxLength="3"
                      :min="0"
                      :max="domainMetrics[0].cputotal"
                      addonAfter="CPUs"
                      required
                      @change="validateInput('server')"
                    />
                  </div>
                  <div style="margin-top: -23px; text-align: center;">
                    <h5 style="margin-left: 10px;">Windows Clients
                      <a-tooltip :title="'Anteil der Windows 10 Lizenzen an der gesamt CPU Anzahl'">
                        <a-icon type="info-circle" style="color: black;" />
                      </a-tooltip>
                    </h5>
                    <a-input
                      class="inputField"
                      id="client"
                      style="margin-left: 10px;"
                      oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                      v-model.number="windowsClients"
                      :maxLength="3"
                      :min="0"
                      :max="domainMetrics[0].cputotal"
                      addonAfter="CPUs"
                      required
                      @change="validateInput('client')"
                    />
                  </div>
                </div>
              </div>
              <div v-if="licenceSum" style="position: relative; left: 8.3em; color: red; float: right;">{{ $t('label.exceeding.licences') }}</div>
              RAM Slider + Input Field
              <span style="padding-left: 35px;"><a-icon type="save"></a-icon>{{ ' vRAM' }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  style="width: 100%;"
                  :min="minMemory"
                  :max="maxMemory"
                  :step="256"
                  v-model.number="runningVMRam"
                />
                <a-input
                  class="inputField"
                  id="memory"
                  style="width: 145px;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model.number="runningVMRam"
                  :maxLength="6"
                  :min="minMemory"
                  :max="maxMemory"
                  addonAfter="MB"
                  required
                />
              </div>
              Primary Storage Slider + Input Fields
              <span style="padding-left: 35px;"><a-icon type="hdd"></a-icon>{{ $t('label.highperformance.storage') }}</span>
              <span style="margin: 10px 0 -10px 55px;"><a-icon type="hdd"></a-icon>{{ ' HDD' }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  style="width: 100%;"
                  :min="minPrimStorage"
                  :max="maxPrimStorage"
                  v-model.number="hddData"/>
                <a-input
                  class="inputField"
                  id="hdd"
                  style="width: 140px;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model.number="hddData"
                  :maxLength="5"
                  :min="minPrimStorage"
                  :max="maxPrimStorage"
                  addonAfter="GB"
                  required
                />
              </div>
              <span style="margin: 0 0 -10px 55px;"><a-icon type="hdd"></a-icon>{{ ' SSD' }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  style="width: 100%;"
                  :min="minPrimStorage"
                  :max="maxPrimStorage"
                  v-model.number="ssdData"/>
                <a-input
                  class="inputField"
                  id="ssd"
                  style="width: 140px;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model.number="ssdData"
                  :maxLength="5"
                  :min="minPrimStorage"
                  :max="maxPrimStorage"
                  addonAfter="GB"
                  required
                />
              </div>
              Secondary Storage Slider + Input Field
              <span style="padding-left: 35px;"><a-icon type="database"></a-icon>{{ ' Snapshots + Templates' }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  style="width: 100%;"
                  :min="minSecStorage"
                  :max="maxSecStorage"
                  v-model.number="domainMetrics[0].secondarystoragetotal"/>
                <a-input
                  class="inputField"
                  id="secStorage"
                  style="width: 140px;"
                  v-model.number="domainMetrics[0].secondarystoragetotal"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  :maxLength="5"
                  :min="minSecStorage"
                  :max="maxSecStorage"
                  addonAfter="GB"
                  required
                />
              </div>
              IP Adresses Slider + Input Field
              <span style="padding-left: 35px;"><a-icon type="environment"></a-icon>{{ ' ' + $t('label.menu.ipaddresses') }}</span>
              <div class="sliderAndInput">
                <Slider
                  name="slider"
                  style="width: 100%;"
                  :min="minIp"
                  :max="maxIp"
                  v-model.number="allocatedIps"/>
                <a-input
                  class="inputField"
                  id="ip"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model.number="allocatedIps"
                  :maxLength="2"
                  :min="minIp"
                  :max="maxIp"
                  addonAfter="IP´s"
                  required
                />
              </div>
              <a-button style="width: 240px; margin: 0 0 5px 5px;" type="primary" @click="listMetrics()">{{ $t('label.reset.setup') }}</a-button>
            </div>
          </div> -->
          <!-- <a-tabs
            v-if="showProject"
            :animated="false"
            @change="onTabChange">
            <template v-for="tab in $route.meta.tabs" :key="tab.name">
              <a-tab-pane
                v-if="'show' in tab ? tab.show(project, $route, $store.getters.userInfo) : true"
                :tab="$t('label.' + tab.name)"
                :key="tab.name">
                <keep-alive>
                  <component
                    :is="tab.component"
                    :resource="project"
                    :loading="loading"
                    :bordered="false"
                    :stats="stats" />
                </keep-alive>
              </a-tab-pane>
            </template>
          </a-tabs> -->
        </a-card>
          <!-- <a-row :gutter="24" v-else>
            <a-col
              class="usage-dashboard-chart-tile"
              :xs="12"
              :md="8"
              v-for="stat in stats"
              :key="stat.type">
              <a-card
                class="usage-dashboard-chart-card"
                :bordered="false"
                :loading="loading"
                :style="stat.bgcolor ? { 'background': stat.bgcolor } : {}">
                <router-link v-if="stat.path" :to="{ path: stat.path, query: stat.query }">
                  <div
                    class="usage-dashboard-chart-card-inner">
                    <h3>{{ stat.name }}</h3>
                    <h2>
                      <render-icon :icon="stat.icon" />
                      {{ stat.count == undefined ? 0 : stat.count }}
                    </h2>
                  </div>
                </router-link>
              </a-card>
            </a-col>
          </a-row>
        </a-card>
      </a-row>
    </a-col>
    <a-col :xl="8">
      <chart-card :loading="loading" >
        <div class="usage-dashboard-chart-card-inner">
          <a-button>
            <router-link :to="{ name: 'event' }">
              {{ $t('label.view') + ' ' + $t('label.events') }}
            </router-link>
          </a-button>
        </div>
        <template #footer>
          <div class="usage-dashboard-chart-footer">
            <a-timeline>
              <a-timeline-item
                v-for="event in events"
                :key="event.id"
                :color="getEventColour(event)">
                <span :style="{ color: '#999' }"><small>{{ $toLocaleDate(event.created) }}</small></span><br/>
                <span :style="{ color: '#666' }"><small><router-link :to="{ path: '/event/' + event.id }">{{ event.type }}</router-link></small></span><br/>
                <resource-label :resourceType="event.resourcetype" :resourceId="event.resourceid" :resourceName="event.resourcename" />
                <span :style="{ color: '#aaa' }">({{ event.username }}) {{ event.description }}</span>
              </a-timeline-item>
            </a-timeline>
          </div>
        </template>
      </chart-card>
    </a-col>
  </a-row> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import { api } from '@/api'
import store from '@/store'
import wpapi from '../../wpApi/myApi'
import api2 from '@/wpApi/api2'
import $ from 'jquery'
import _ from 'lodash'
import { LaptopOutlined, FileDoneOutlined, QrcodeOutlined } from '@ant-design/icons-vue'

import ChartCard from '@/components/widgets/ChartCard'
import ApexChart from '@/components/view/ApexChart.vue'
import UsageDashboardChart from '@/views/dashboard/UsageDashboardChart'
import ResourceLabel from '@/components/widgets/ResourceLabel'

/**
 * Component of the Dashboard which is displayed after login.
 * Displays a overview over costs/discounts of the current setup and daily/monthly data charts for detailed expection of progress of costs.
 * @vue-prop {Object} resource - resource of the page, in this case VPC.
 * @vue-data {String} accountName - accountname of the logged in account.
 * @vue-data {Boolean} ngfBoolean - is a ngf active or not?
 * @vue-data {Boolean} loading - manages the loading state of the component.
 * @vue-computed {String} accountIdInput - returns the selected accountUUID, if one is selected, or the accountUUID of the logged in user otherwise.
 * @vue-computed {String} accountNameInput - returns the selected accountName, if one is selected, or the accountName of the logged in user otherwise.
 * @vue-computed {String} domainIdInput - returns the selected domainUUID, if one is selected, or the domainUUID of the logged in user otherwise.
 * @vue-computed {Integer} getCurrentYear - returns the current year.
 * @vue-computed {Integer} totalLicenseCost - Return summed costs of all WindowsServers owned by the account if there are vm's running with WindowsServer templates.
 * @vue-computed {Integer} totalLicenseCostDiscounted - Return summed discounted costs of all WindowsServers owned by the account if there is a discount set for Windows Server usage type.
 * @vue-computed {Integer} windowsClientCost - Return summed costs of all windowsClients owned by the account if there are vm's running with WindowsClient templates.
 * @vue-computed {Integer} windowsClientCostDiscounted - Return summed discounted costs of all windowsClients owned by the account if there is a discount set for Windows Client usage type.
 * @vue-computed {Integer} ramCost - Return summed costs of all RAM owned by the account if existing - 0 otherwise.
 * @vue-computed {Integer} ramCostDiscounted - Return summed discounted costs of all RAM owned by the account if existing - 0 or ramCost otherwise.
 * @vue-computed {Integer} hddStorCost - Return summed costs of all HDD storage owned by the account if existing - 0 otherwise.
 * @vue-computed {Integer} hddStorCostDiscounted - Return summed discounted costs of all HDD storage owned by the account if existing - 0 or hddStorCost otherwise.
 * @vue-computed {Integer} totalLicenseCost - Return summed costs of all WindowsServers owned by the account if there are vm's running with WindowsServer templates
 * @vue-computed {Integer} totalLicenseCostDiscounted - Return summed discounted costs of all WindowsServers owned by the account if there is a discount set for Windows Server usage type.
 * @vue-computed {Integer} totalLicenseCost - Return summed costs of all WindowsServers owned by the account if there are vm's running with WindowsServer templates
 * @vue-computed {Integer} totalLicenseCostDiscounted - Return summed discounted costs of all WindowsServers owned by the account if there is a discount set for Windows Server usage type.
 * @vue-computed {Integer} totalLicenseCost - Return summed costs of all WindowsServers owned by the account if there are vm's running with WindowsServer templates
 * @vue-computed {Integer} totalLicenseCostDiscounted - Return summed discounted costs of all WindowsServers owned by the account if there is a discount set for Windows Server usage type.
 * @vue-computed {Integer} totalLicenseCost - Return summed costs of all WindowsServers owned by the account if there are vm's running with WindowsServer templates
 * @vue-computed {Integer} totalLicenseCostDiscounted - Return summed discounted costs of all WindowsServers owned by the account if there is a discount set for Windows Server usage type.
 * @vue-computed {String} domainIdInput - returns the selected domainUUID, if one is selected, or the domainUUID of the logged in user otherwise
 * @vue-computed {String} domainIdInput - returns the selected domainUUID, if one is selected, or the domainUUID of the logged in user otherwise
 */
export default {
  name: 'UsageDashboard',
  components: {
    ChartCard,
    UsageDashboardChart,
    ResourceLabel,
    ApexChart,
    LaptopOutlined,
    FileDoneOutlined,
    QrcodeOutlined
  },
  props: {
    resource: {
      type: Object,
      default () {
        return {}
      }
    },
    showProject: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hddOfferingList: [],
      /* testResponse: {
        HDD: [
          {
            id: 76,
            uuid: '6f46636c-135b-490c-9a78-02a0f77ae744'
          },
          {
            id: 23,
            uuid: '65c63c75-1091-4a31-b459-6ad02c5aa362'
          },
          {
            id: 16,
            uuid: '1113fc2b-4b24-4469-b696-24630f272dfa'
          },
          {
            id: 18,
            uuid: '198124-4b24-4469-b696-24630f272dfa'
          },
          {
            id: 4,
            uuid: '124512-4b24-4469-b696-24630f272dfa'
          }
        ],
        SSD: [
          {
            id: 55,
            uuid: 'e2d6e561-ed4f-4b3b-bd5f-9a0e06a856f8'
          },
          {
            id: 55,
            uuid: '7a03fc2b-4b24-4469-b696-24630f272dfa'
          },
          {
            id: 55,
            uuid: 'e2d6e561-ed4f-4b3b-bd5f-9a0e06a856f84'
          },
          {
            id: 55,
            uuid: 'e2d6e561-ed4f-4b3b-bd5f-9a0e06a856f8'
          }
        ]
      }, */
      acronisCssData: [],
      acronisPbsData: [],
      acronisMaintenanceData: [],
      enabledNgfs: 0,
      subAccounts: [],
      selectedAccDomainUuid: null,
      selectedAccAccountUuid: null,
      selectedAccName: null,
      loading: false,
      noBill: false,
      noBillVmList: [],
      changeAccountDisplayLoading: false,
      domainID: this.$store.getters.userInfo.domainid,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      allTags: [],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      billingList: [],
      finalChartCostSummary: [],
      openLicenseDetailsModal: false,
      isDiscounted: true,
      dataLoaded: false,
      showAddAccount: false,
      licenceSum: false,
      events: [],
      singleLicenseTypePrice: [],
      hddData: 0,
      ssdData: 0,
      snapshotData: 0,
      isoData: 0,
      value: 0,
      vpcs: [],
      templateData: 0,
      domainMetrics: [],
      allocatedIps: [],
      currentSetup: Array,
      currentLicences: [],
      currentCPUandRAM: [],
      currentSSDandHDD: [],
      currentIpData: [],
      discountList: [],
      serverVMInfo: {},
      clientVMInfo: {},
      licenseDetailsInput: [],
      columns: [],
      windowsServer: 0,
      windowsClients: 0,
      allVmTagInfo: [],
      allVms: [],
      vmsWithNoBill: [],
      vmsWithFixedPrice: [],
      groupedTagInfo: [],
      runningVMCPU: 0,
      runningVMRam: 0,
      licenceDetailsToggled: false,
      noBillToggled: false,
      cssToggled: false,
      stats: [],
      metricsLoaded: false,
      project: {},
      currentQuota: [],
      minCpu: 0,
      maxCpu: 128,
      minMemory: 0,
      maxMemory: 512000,
      minPrimStorage: 0,
      maxPrimStorage: 25000,
      minSecStorage: 0,
      maxSecStorage: 50000,
      minIp: 1,
      maxIp: 16,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      k8sDeployments: [],
      totalDeploymentInfo: {}
    }
  },
  computed: {
    isStatsEmpty: function () {
      return Object.keys(this.stats[0]).length === 0
    },
    accountIdInput: function () {
      if (this.selectedAccAccountUuid === null) {
        return this.accountUuid
      } else { return this.selectedAccAccountUuid }
    },
    accountNameInput: function () {
      if (this.selectedAccName === null) {
        return this.accountName
      } else { return this.selectedAccName }
    },
    domainIdInput: function () {
      if (this.selectedAccDomainUuid === null) {
        return this.domainID
      } else { return this.selectedAccDomainUuid }
    },
    getCurrentYear: function () {
      var d = new Date()
      return d.getFullYear()
    },
    // check if all values are the same as the current setup or not
    isCurrentSetupActive: function () {
      if (JSON.stringify(this.domainMetrics) === JSON.stringify(this.currentSetup) && JSON.stringify(this.allocatedIps) === JSON.stringify(this.currentIpData) && JSON.stringify(this.currentLicences) === JSON.stringify([this.windowsServer, this.windowsClients]) && JSON.stringify(this.currentCPUandRAM) === JSON.stringify([this.runningVMCPU, this.runningVMRam]) && JSON.stringify(this.currentSSDandHDD) === JSON.stringify([this.ssdData, this.hddData])) {
        return true
      } else {
        return false
      }
    },
    // calculate costs with discount or without, depending on if there is a discount set or not
    cpuCost: function () {
      if (this.runningVMCPU === 0 | this.runningVMCPU === null | this.runningVMCPU === '0') {
        return 0
      } else {
        return (this.runningVMCPU + (this.totalDeploymentInfo.totalDeploymentCpus || 0)) * this.discountList.filter(x => x.usageName === 'vCPU')[0].quotaTariff
      }
    },
    cpuCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'vCPU')[0].discount !== 0) {
        return (this.runningVMCPU + (this.totalDeploymentInfo.totalDeploymentCpus || 0)) * this.discountList.filter(x => x.usageName === 'vCPU')[0].discountCalculated
      } else {
        return this.cpuCost
      }
    },
    totalLicenseCost: function () {
      var totalLicenseCosts = 0
      if (this.allVmTagInfo.length > 0 && this.groupedTagInfo.length > 0) {
        for (var tag of this.groupedTagInfo) {
          totalLicenseCosts += (tag.licenseCounter * tag.quotaTariff)
        }
        return totalLicenseCosts
      } else return 0
    },
    totalLicenseCostDiscounted: function () {
      var totalLicenseCosts = 0
      if (this.allVmTagInfo.length > 0 && this.groupedTagInfo.length > 0) {
        for (var tag of this.groupedTagInfo) {
          totalLicenseCosts += (tag.licenseCounter * tag.discountCalculated)
        }
        return totalLicenseCosts
      } else return 0
    },
    windowsClientCost: function () {
      if (this.allVmTagInfo.length > 0 && this.windowsClients) {
        if (this.discountList.filter(x => x.usageName === (this.allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff !== undefined) {
          return this.windowsClients * this.discountList.filter(x => x.usageName === (this.allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].quotaTariff
        } else {
          return 0
        }
      } else return 0
    },
    windowsClientCostDiscounted: function () {
      if (this.allVmTagInfo.length > 0 && this.windowsClients) {
        if (this.discountList.filter(x => x.usageName === (this.allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].discount !== 0) {
          return this.windowsClients * this.discountList.filter(x => x.usageName === (this.allVmTagInfo.filter(x => x.tagText.toLowerCase().indexOf('server') === -1 && x.tagType === 'OS')[0].tagText))[0].discountCalculated
        } else {
          return this.windowsClientCost
        }
      } else return 0
    },
    ramCost: function () {
      return (this.runningVMRam + ((this.totalDeploymentInfo.totalDeploymentRam || 0) * 1024)) * this.discountList.filter(x => x.usageName === 'MEMORY')[0].quotaTariff
    },
    ramCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'MEMORY')[0].discount !== 0) {
        return (this.runningVMRam + ((this.totalDeploymentInfo.totalDeploymentRam || 0) * 1024)) * this.discountList.filter(x => x.usageName === 'MEMORY')[0].discountCalculated
      } else {
        return this.ramCost
      }
    },
    hddStorCost: function () {
      return this.hddData * this.discountList.filter(x => x.usageName === 'HDD')[0].quotaTariff
    },
    hddStorCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'HDD')[0].discount !== 0) {
        return this.hddData * this.discountList.filter(x => x.usageName === 'HDD')[0].discountCalculated
      } else {
        return this.hddStorCost
      }
    },
    ssdStorCost: function () {
      return (this.ssdData + (this.totalDeploymentInfo.totalDeploymentDisk || 0)) * this.discountList.filter(x => x.usageName === 'SSD')[0].quotaTariff
    },
    ssdStorCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'SSD')[0].discount !== 0) {
        return (this.ssdData + (this.totalDeploymentInfo.totalDeploymentDisk || 0)) * this.discountList.filter(x => x.usageName === 'SSD')[0].discountCalculated
      } else {
        return this.ssdStorCost
      }
    },
    secStorCost: function () {
      return (this.templateData + this.isoData + this.snapshotData) * this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].quotaTariff
    },
    secStorCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discount !== 0) {
        return (this.templateData + this.isoData + this.snapshotData) * this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discountCalculated
      } else {
        return this.secStorCost
      }
    },
    isoCost: function () {
      return this.isoData * this.discountList.filter(x => x.usageName === 'ISO')[0].quotaTariff
    },
    isoCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'ISO')[0].discount !== 0) {
        return this.isoData * this.discountList.filter(x => x.usageName === 'ISO')[0].discountCalculated
      } else {
        return this.isoCost
      }
    },
    templateCost: function () {
      return this.templateData * this.discountList.filter(x => x.usageName === 'TEMPLATE')[0].quotaTariff
    },
    templateCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'TEMPLATE')[0].discount !== 0) {
        return this.templateData * this.discountList.filter(x => x.usageName === 'TEMPLATE')[0].discountCalculated
      } else {
        return this.templateCost
      }
    },
    snapshotCost: function () {
      return this.snapshotData * this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].quotaTariff
    },
    snapshotCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discount !== 0) {
        return this.snapshotData * this.discountList.filter(x => x.usageName === 'SNAPSHOT')[0].discountCalculated
      } else {
        return this.snapshotCost
      }
    },
    ipCost: function () {
      return this.allocatedIps * this.discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].quotaTariff
    },
    ipCostDiscounted: function () {
      if (this.discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discount !== 0) {
        return this.allocatedIps * this.discountList.filter(x => x.usageName === 'IP_ADDRESS')[0].discountCalculated
      } else {
        return this.ipCost
      }
    },
    ngfCost: function () {
      const quotaTariff = this.discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0]
      if (quotaTariff !== undefined) {
        return this.enabledNgfs * (quotaTariff.quotaTariff === undefined ? 0 : quotaTariff.quotaTariff)
      } else { return 0 }
    },
    ngfCostDiscounted: function () {
      const discount = this.discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0]
      if (discount.discount !== undefined) {
        if (discount.discount !== 0) {
          return this.enabledNgfs * discount.discountCalculated
        } else {
          return this.ngfCost
        }
      } else { return 0 }
    },
    rbsCssCost: function () {
      const quotaTariff = this.discountList.filter(x => x.usageName === 'AcronisCSS')[0]
      if (quotaTariff !== undefined) {
        return this.acronisCssData * (quotaTariff.quotaTariff === undefined ? 0 : quotaTariff.quotaTariff)
      } else { return 0 }
    },
    rbsCssCostDiscounted: function () {
      const discount = this.discountList.filter(x => x.usageName === 'AcronisCSS')[0]
      if (discount.discount !== undefined) {
        if (discount.discount !== 0) {
          return this.acronisCssData * discount.discountCalculated
        } else {
          return this.rbsCssCost
        }
      } else { return 0 }
    },
    rbsCost: function () {
      const quotaTariff = this.discountList.filter(x => x.usageName === 'AcronisRBS')[0]
      if (quotaTariff !== undefined) {
        return this.acronisPbsData * (quotaTariff.quotaTariff === undefined ? 0 : quotaTariff.quotaTariff)
      } else { return 0 }
    },
    rbsCostDiscounted: function () {
      const discount = this.discountList.filter(x => x.usageName === 'AcronisRBS')[0]
      if (discount.discount !== undefined) {
        if (discount.discount !== 0) {
          return this.acronisPbsData * discount.discountCalculated
        } else {
          return this.rbsCost
        }
      } else { return 0 }
    },
    acronisMaintenanceCost: function () {
      const quotaTariff = this.discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0]
      if (quotaTariff !== undefined) {
        return this.acronisMaintenanceData * (quotaTariff.quotaTariff === undefined ? 0 : quotaTariff.quotaTariff)
      } else { return 0 }
    },
    acronisMaintenanceCostDiscounted: function () {
      const discount = this.discountList.filter(x => x.usageName === 'Cyber Cloud Endpoint')[0]
      if (discount.discount !== undefined) {
        if (discount.discount !== 0) {
          return this.acronisMaintenanceData * discount.discountCalculated
        } else {
          return this.acronisMaintenanceCost
        }
      } else { return 0 }
    },
    totalSoftwareLicenseCost: function () {
      if (this.allVmTagInfo.length > 0) {
        var totalCost = 0
        for (var vm in this.allVmTagInfo) {
          if (this.allVmTagInfo[vm].tagType !== 'OS' || this.allVmTagInfo[vm].tagType !== 'Software Package') {
            totalCost += (this.allVmTagInfo[vm].licenseCounter * this.discountList.filter(x => x.usageName === this.allVmTagInfo[vm].tagText)[0].discountCalculated)
          }
          if (this.allVmTagInfo[vm].tagType === 'Software Package') {
            totalCost += this.discountList.filter(x => x.usageName === this.allVmTagInfo[vm].tagText)[0].discountCalculated
          }
        }
        return totalCost
      } else return 0
    },
    totalSoftwareLicenseAmount: function () {
      if (this.allVmTagInfo.length > 0) {
        var totalAmount = 0
        for (var license in this.allVmTagInfo) {
          if (this.allVmTagInfo[license].tagType !== 'OS' && this.vmsWithNoBill.indexOf(this.allVmTagInfo[license].uuId) === -1) {
            totalAmount += this.allVmTagInfo[license].licenseCounter
          }
        }
        return totalAmount
      } else return 0
    },
    totalCost: function () {
      return this.cpuCost + this.ramCost + this.secStorCost + this.ipCost + this.totalLicenseCost + this.hddStorCost + this.ssdStorCost + (this.isAnyNgfActive ? this.ngfCost : 0) + this.rbsCssCost + this.rbsCost + this.acronisMaintenanceCost
    },
    totalCostDiscounted: function () {
      return this.cpuCostDiscounted + this.ramCostDiscounted + this.secStorCostDiscounted + this.ipCostDiscounted + this.totalLicenseCostDiscounted + this.hddStorCostDiscounted + this.ssdStorCostDiscounted + (this.isAnyNgfActive ? this.ngfCostDiscounted : 0) + this.rbsCssCostDiscounted + this.rbsCostDiscounted + this.acronisMaintenanceCostDiscounted
    },
    getMonth: function () {
      var d = new Date()
      var month = []
      month[0] = '01'
      month[1] = '02'
      month[2] = '03'
      month[3] = '04'
      month[4] = '05'
      month[5] = '06'
      month[6] = '07'
      month[7] = '08'
      month[8] = '09'
      month[9] = '10'
      month[10] = '11'
      month[11] = '12'
      return month[d.getMonth()]
    },
    getCurrentMonth: function () {
      var d = new Date()
      return this.months[d.getMonth()]
    },
    getCurrentDay: function () {
      var d = new Date()
      return d.getDate()
    },
    getFirstDay: function () {
      var d = new Date()
      return new Date(d.getFullYear(), d.getMonth(), 1).getDate()
    },
    getLastDay: function () {
      var d = new Date()
      return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()
    },
    getPastDaysAsPercent: function () {
      var d = new Date()
      // var firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1).getDate()
      var lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()
      return 100 / lastDayOfMonth * d.getDate()
    },
    getCurrentDate: function () {
      var d = new Date()
      return this.getCurrentDay + '.' + this.getMonth + '.' + d.getFullYear()
    },
    isAnyNgfActive: function () {
      const ngf = this.discountList.filter(x => x.usageName === 'NextGenerationFirewall')[0]
      if (ngf !== undefined) {
        return true
      } else { return false }
    }
  },
  created () {
    this.$store.watch(
      (state, getters) => getters.project,
      (newValue, oldValue) => {
        if (newValue && newValue.id && (!oldValue || newValue.id !== oldValue.id)) {
          this.fetchData()
        } else if (store.getters.userInfo.roletype !== 'Admin') {
          this.fetchData()
        }
      }
    )
  },
  async mounted () {
    this.loading = true
    if (this.$store.getters.userInfo.rolename === 'ResellerAdmin') {
      await this.fetchResellerCustomerAccounts()
    }
    await this.fetchAllTagInfo()
    await this.getDiscountList()
    await this.fetchData()
    this.getSnapshotSum()
    this.getIsoSum()
    this.getTemplateSum()
    this.dataLoaded = true
    await this.getAcronisCss()
    await this.getAcronisPbs()
    await this.getAcronisMaintenance()
    this.populateQuotaSummary()
    this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered --> Collapsable columns are collapsed by default when the page is loaded.
      $('.collapsable').toggleClass('expand').nextUntil($('.endCollapse')).toggle()
    })
    // await this.listMetrics()
    // this.project = store.getters.project
    await this.createQuotaBillingList()
    // this.resizeContainer()
    /* this.currentSetup = JSON.parse(JSON.stringify(this.domainMetrics))
    this.currentLicences = JSON.parse(JSON.stringify([this.windowsServer, this.windowsClients]))
    this.currentCPUandRAM = JSON.parse(JSON.stringify([this.runningVMCPU, this.runningVMRam]))
    this.currentSSDandHDD = JSON.parse(JSON.stringify([this.ssdData, this.hddData]))
    this.currentIpData = JSON.parse(JSON.stringify(this.allocatedIps)) */
    this.loading = false
  },
  watch: {
    '$route' (to) {
      if (to.name === 'dashboard') {
        this.fetchData()
        this.buildLicenseDetailTable()
      }
    },
    resource: {
      deep: true,
      handler (newData, oldData) {
        // this.project = newData
      }
    },
    '$i18n.global.locale' (to, from) {
      if (to !== from) {
        this.fetchData()
        this.buildLicenseDetailTable()
      }
    }
  },
  methods: {
    async getDeploymentData () {
      var params = new FormData()
      params.append('accountuuid', this.accountIdInput)
      var totalDeploymentParams = { totalDeploymentCpus: 0, totalDeploymentRam: 0, totalDeploymentDisk: 0, totalDeploymentlicenses: [] }
      await api2.getK8sDeploymentList(params).then(response => {
        var deployments = response.data.deployments
        if (deployments === null) {
          this.loading = false
          this.k8sDeployments = []
        } else {
          for (var deployment of deployments) {
            deployment.uuId = this.accountIdInput
            totalDeploymentParams.totalDeploymentCpus += deployment.cpuLimit
            totalDeploymentParams.totalDeploymentRam += deployment.memLimit
            totalDeploymentParams.totalDeploymentDisk += parseInt(deployment.disksize)
            if (deployment.licenses.length > 0) {
              var tagInfo = this.allTags.filter(tag => tag.id === parseInt(deployment.licenses[0].licenseId))[0]
              deployment.licenses[0].tagText = tagInfo.tagText
              deployment.licenses[0].tagColor = tagInfo.tagColor
              deployment.licenses[0].tagType = tagInfo.tagType
              deployment.licenses[0].id = tagInfo.id
              deployment.licenses[0].licenseCounter = parseInt(deployment.licenses[0].count)
              totalDeploymentParams.totalDeploymentlicenses.push(...deployment.licenses)
            }
          }
          this.k8sDeployments = response.data.deployments === null ? [] : response.data.deployments
          this.loading = false
        }
        this.totalDeploymentInfo = totalDeploymentParams
      }).catch(error => {
        this.loading = false
        this.$notifyError(error)
      })
    },
    async getAcronisCss () {
      var params = new FormData()
      params.append('accountUUId', this.accountIdInput)
      await wpapi.getAcronisCss(params).then(response => {
        this.acronisCssData = response.data ? response.data : 0
      }).catch(error => {
        console.log(error)
      })
    },
    async getAcronisPbs () {
      await wpapi.getAcronisRbs(this.accountIdInput).then(response => {
        this.acronisPbsData = response.data ? response.data : 0
      }).catch(error => {
        console.log(error)
      })
    },
    async getAcronisMaintenance () {
      var params = new FormData()
      params.append('accountUUId', this.accountIdInput)
      await wpapi.getAcronisMaintenance(params).then(response => {
        this.acronisMaintenanceData = response.data ? response.data : 0
      }).catch(error => {
        console.log(error)
      })
    },
    /**
     * - Fetch the billing list with all used usage types and prices (with and without discount) for the curently logged in user.
     * - Fetch the monthly data for the apexCharts in the Dashboard for the current year.
     */
    async createQuotaBillingList () {
      await wpapi.saveMonthlySummaryBillingList(this.accountIdInput, this.domainIdInput).then(response => {
        this.billingList = response.data
      }).catch(error => {
        console.log(error)
        this.changeAccountDisplayLoading = false
      })
      await wpapi.getMonthlySummaryBillingList(this.accountIdInput, this.getCurrentYear).then(response => {
        // JSON responses are automatically parsed.
        this.finalChartCostSummary = response.data
      }).catch(error => {
        console.log(error)
        this.changeAccountDisplayLoading = false
      })
    },
    /**
     * Fetch the monthly data for the apexCharts in the Dashboard for the input year.
     * @param {Integer} year Year that the chart data should be generated for
     */
    async getOnlyMonthlySummary (year) {
      await wpapi.getMonthlySummaryBillingList(this.accountIdInput, year).then(response => {
        // JSON responses are automatically parsed.
        this.finalChartCostSummary = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    /**
     * Fetch all subAccounts of the logged in ResellerAdmin Account.
     */
    async fetchResellerCustomerAccounts () {
      await wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
        this.subAccounts = response.data.filter(x => x.state === 'enabled' && x.removed === null)
      }).catch(error => {
        console.log(error)
      })
    },
    async accountSelected (value) {
      await this.fetchAllTagInfo()
      if (!this.licenceDetailsToggled) {
        this.collapseRow('licences', 0)
      }
      if (!this.noBillToggled) {
        this.collapseRow('noBill', 0)
      }
      if (!this.cssToggled) {
        this.collapseRow('csrbs', 0)
      }
      this.changeAccountDisplayLoading = true
      if (value === this.accountName) {
        this.selectedAccDomainUuid = null
        this.selectedAccAccountUuid = null
        this.selectedAccName = null
      } else {
        const selectedAccount = this.subAccounts.filter(x => x.accountName === value)[0]
        this.selectedAccDomainUuid = selectedAccount.domainUuid
        this.selectedAccAccountUuid = selectedAccount.uuid
        this.selectedAccName = selectedAccount.accountName
      }
      await this.fetchData()
      await this.getDiscountList()
      await this.buildLicenseDetailTable()
      this.getSnapshotSum()
      this.getIsoSum()
      this.getTemplateSum()
      await this.getAcronisCss()
      await this.getAcronisPbs()
      await this.getAcronisMaintenance()
      this.dataLoaded = true
      this.populateQuotaSummary()
      this.$store.watch(
        (state, getters) => getters.project,
        (newValue, oldValue) => {
          if (newValue && newValue.id) {
            this.fetchData()
            this.buildLicenseDetailTable()
          }
        }
      )
      await this.createQuotaBillingList()
      this.changeAccountDisplayLoading = false
      // this.collapseRow('licences', 0)
      if (this.licenceDetailsToggled) {
        this.collapseRow('licences', 0)
      }
      if (this.noBillToggled) {
        this.collapseRow('noBill', 0)
      }
      if (this.cssToggled) {
        this.collapseRow('csrbs', 0)
      }
      // this.collapseRow('noBill', 0)
    },
    handleSearch (selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    /**
     * Fetch all Licensetags and their info.
     */
    async fetchAllTagInfo () {
      await wpapi.getAllTags().then(response => {
        this.allTags = response.data
      })
    },
    async buildLicenseDetailTable () {
      this.groupedTagInfo = []
      var noBillLicenses = this.allVmTagInfo.filter(x => x.tagText === 'NoBill')
      var fixedPriceLicenses = this.allVmTagInfo.filter(x => x.tagType === 'Fixed Price')
      if (fixedPriceLicenses.length > 0) {
        for (var fixLicense of fixedPriceLicenses) {
          this.vmsWithFixedPrice.push(fixLicense.uuId)
        }
      }
      if (noBillLicenses.length > 0) {
        this.noBill = true
        for (var license of noBillLicenses) {
          this.vmsWithNoBill.push(license.uuId)
        }
      }
      if (this.allVmTagInfo.length > 0) {
        var groupListByTagNAme = _.groupBy(this.allVmTagInfo, 'tagText')
        for (var tag in groupListByTagNAme) {
          if (tag === 'GPU') {
            continue
          }
          var temptagInfo = {}
          temptagInfo.tagName = tag
          var licenseNr = 0
          for (var vm in groupListByTagNAme[tag]) {
            temptagInfo.tagColor = groupListByTagNAme[tag][vm].tagColor
            temptagInfo.tagType = groupListByTagNAme[tag][vm].tagType
            temptagInfo.vmName = groupListByTagNAme[tag][vm].name
            // if license vm does not appear in the vmsWithNoBill or vmsWithFixedPrice List
            if (this.vmsWithNoBill.indexOf(groupListByTagNAme[tag][vm].uuId) === -1 && this.vmsWithFixedPrice.indexOf(groupListByTagNAme[tag][vm].uuId) === -1) {
              if (temptagInfo.tagType === 'OS') {
                licenseNr += this.allVms.filter(vm => vm.name === temptagInfo.vmName)[0] !== undefined ? this.allVms.filter(vm => vm.name === temptagInfo.vmName)[0].cpunumber : 0
              } else {
                licenseNr += groupListByTagNAme[tag][vm].licenseCounter
              }
              // hardware licenses should still be billed, even if they are connected to a FixedPrice license
            } else if (temptagInfo.tagType === 'Hardware') {
              licenseNr += groupListByTagNAme[tag][vm].licenseCounter
            }
            if (groupListByTagNAme[tag][vm].tagType === 'Fixed Price') {
              licenseNr += groupListByTagNAme[tag][vm].licenseCounter
            }
          }
          temptagInfo.licenseCounter = licenseNr
          // if noBill license
          if (temptagInfo.tagName === 'NoBill') {
            temptagInfo.quotaTariff = 0
            temptagInfo.discountCalculated = 0
            temptagInfo.discount = 0
          } else {
            // if no noBill or Fixed Price license: set all tariffs and discounts to 0
            temptagInfo.quotaTariff = this.discountList.filter(x => x.usageName === tag)[0].quotaTariff !== undefined ? this.discountList.filter(x => x.usageName === tag)[0].quotaTariff : 0
            temptagInfo.discountCalculated = this.discountList.filter(x => x.usageName === tag)[0].discountCalculated !== undefined ? this.discountList.filter(x => x.usageName === tag)[0].discountCalculated : 0
            temptagInfo.discount = this.discountList.filter(x => x.usageName === tag)[0].discount !== undefined ? this.discountList.filter(x => x.usageName === tag)[0].discount : 0
          }
          this.groupedTagInfo.push(temptagInfo)
        }
        // this is the really complicated way to populate the license details table - should be improved!!
        var finalList = []
        // for all vms for this user
        for (var vmtest in this.allVms) {
          var tempList = {}
          tempList.vmName = this.allVms[vmtest].name
          // predefine vmName column
          var myColumns = []
          var fillColumnsWithName = {}
          fillColumnsWithName.dataIndex = 'vmName'
          fillColumnsWithName.key = 'vmname'
          fillColumnsWithName.title = 'VM'
          fillColumnsWithName.slots = { filterDropdown: 'filterDropdown', filterIcon: 'filterIcon', customRender: 'customRender' }
          fillColumnsWithName.onFilter = (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase())
          fillColumnsWithName.onFilterDropdownVisibleChange = visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          }
          // push column to myColumns
          myColumns.push(fillColumnsWithName)
          // for each tag, fill a column and push it to myColumns
          for (var x in groupListByTagNAme) {
            tempList[x] = '-'
            var fillColumns = {}
            fillColumns.dataIndex = x
            fillColumns.key = x.toLowerCase()
            fillColumns.title = x/* () => { return <a-tag color="blue">{ x }</a-tag> } */
            fillColumns.align = 'center'
            myColumns.push(fillColumns)
          }
          // for each tagname in groupListByTagNAme
          for (var tagName in groupListByTagNAme) {
            // for each tagEntry
            for (var tagInfo in groupListByTagNAme[tagName]) {
              if (groupListByTagNAme[tagName][tagInfo].name === this.allVms[vmtest].name) {
                if (groupListByTagNAme[tagName][tagInfo].tagType === 'OS') {
                  tempList[tagName] = this.allVms[vmtest].cpunumber
                } else if (groupListByTagNAme[tagName][tagInfo].tagType === 'Software per Core' || groupListByTagNAme[tagName][tagInfo].tagType === 'Software per User' || groupListByTagNAme[tagName][tagInfo].tagType === 'Hardware') {
                  tempList[tagName] = groupListByTagNAme[tagName][tagInfo].licenseCounter
                } else if (groupListByTagNAme[tagName][tagInfo].tagType === 'Software Package') {
                  tempList[tagName] = '1'
                } else {
                  tempList[tagName] = '-'
                }
              }
            }
          }
          finalList.push(tempList)
        }
        this.licenseDetailsInput = finalList
        this.columns = myColumns
      }
    },
    async getDiskofferingAndTemplate () {
      await wpapi.getDiskofferingAndTemplateName(this.accountIdInput).then(response => {
      })
    },
    async getSnapshotSum () {
      await api('listSnapshots', { account: this.accountNameInput, domainid: this.domainIdInput }).then(json => {
        var snapshotList = json.listsnapshotsresponse.snapshot
        var snapshotSizeSum = 0
        for (var index in snapshotList) {
          snapshotSizeSum = snapshotSizeSum + (snapshotList[index].virtualsize / 1024 / 1024 / 1024)
        }
        this.snapshotData = snapshotSizeSum
      })
        .catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        })
    },
    async getTemplateSum () {
      await api('listTemplates', { account: this.accountNameInput, domainid: this.domainIdInput, templatefilter: 'selfexecutable' }).then(json => {
        var templateList = json.listtemplatesresponse.template
        var templateSizeSum = 0
        for (var index in templateList) {
          if (templateList[index].account === this.accountNameInput) {
            templateSizeSum = templateSizeSum + (templateList[index].physicalsize / 1024 / 1024 / 1024)
          }
        }
        this.templateData = templateSizeSum
      })
        .catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        })
    },
    async getIsoSum () {
      await api('listIsos', { account: this.accountNameInput, domainid: this.domainIdInput, isofilter: 'selfexecutable' }).then(json => {
        var isoList = json.listisosresponse.iso
        var isoSizeSum = 0
        for (var index in isoList) {
          if (isoList[index].account === this.accountNameInput) {
            isoSizeSum = isoSizeSum + (isoList[index].size / 1024 / 1024 / 1024)
          }
        }
        this.isoData = isoSizeSum
      })
        .catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        })
    },
    populateQuotaSummary () {
      var monthyParams = new FormData()
      monthyParams.append('accountuuid', this.accountIdInput)
      monthyParams.append('month', this.getCurrentMonth)
      wpapi.monthlyQuotaDashboard(monthyParams).then(response => {
        if (response.data === 'nu') {
          this.currentQuota = 0
        } else { this.currentQuota = response.data }
        // this.currentQuota = response.data
      }).catch(error => {
        this.$notifyError(error)
        this.changeAccountDisplayLoading = false
      })
      /* wpapi.createDiscount(this.accountIdInput).then(response => {
      })
        .catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        }) */
      /* wpapi.actualQuotaSummaryDiscounted(this.accountIdInput, this.domainIdInput).then(response => {
      })
        .catch(error => {
          this.$notifyError(error)
        })
 */
      /* wpapi.actualQuotaSummary(this.accountIdInput).then(response => {
        this.currentQuota = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        }) */
    },
    /**
     * Validate Input fields, depending on which values they represent (most check for min and max values).
     *
     * @param {String} input Current Input Value
     * @public
     */
    validateInput (input) {
      let max

      switch (input) {
        case 'cpu':
          max = this.maxCpu
          if ((this.windowsServer + this.windowsClients) > this.runningVMCPU) {
            this.licenceSum = true
          } else {
            this.licenceSum = false
          }
          break
        case 'memory':
          max = this.maxMemory
          break
        case 'primStorage':
          max = this.maxPrimStorage
          break
        case 'secStorage':
          max = this.maxSecStorage
          break
        case 'ip':
          max = this.maxIp
          break
        case 'server':
          max = this.domainMetrics[0].cputotal
          if (this.windowsServer > max) {
            this.licenceSum = true
          } else {
            this.licenceSum = false
          }
          if ((this.windowsServer + this.windowsClients) > this.runningVMCPU) {
            this.licenceSum = true
          } else {
            this.licenceSum = false
          }
          break
        case 'client':
          max = this.domainMetrics[0].cputotal
          if (this.windowsClients > max) {
            this.licenceSum = true
          } else {
            this.licenceSum = false
          }
          if ((this.windowsServer + this.windowsClients) > this.runningVMCPU) {
            this.licenceSum = true
          } else {
            this.licenceSum = false
          }
          break
      }
    },
    openLicenseDetails () {
      this.openLicenseDetailsModal = true
    },
    /**
     * Fetch the discount list with all Usage Types and prices
     *
     * @public
     */
    async getDiscountList () {
      await wpapi.discountListApi(this.accountIdInput).then(response => {
        this.discountList = response.data
      })
        .catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        })
    },
    /**
     * List various metrics via the "listDomains" API that are then saved as a "domainMetrics" data variable.
     * This function also calls the fetchData() function in the end.
     * @public
     */
    async listMetrics () {
      const params = {
        id: this.domainIdInput
      }
      this.loading = true
      await api('listDomains', params).then(json => {
        this.domainMetrics = []
        this.loading = false
        if (json && json.listdomainsresponse) {
          this.domainMetrics = json.listdomainsresponse.domain
        }
      })
      await this.buildLicenseDetailTable()
      await this.fetchData()
      this.licenceSum = false
    },
    /**
     * Fetches VM's (Running State, Stopped State and All), Volumes, Networks, Public IP's and VPC's via thrunningVMCPUspinneir corresponding API. Primarily to display their sum in the table left to the cost overview.
     * For running VM's, the amount of CPU's and RAM is summed up and saved in the runningVMCPU and runningVMRam data variables.
     * Same goes for the amount of CPU cores from Windows Server and Clients VM's. The Output here are lists of Tuples with [VM Name, CPU Amount].
     * For Volumes, the total size of hdd and ssd volumes are gathered and recalculated into GB. That data is saved in the data variables hddData and ssdData.
     *
     * @public
     */
    async fetchData () {
      this.metricsLoaded = false
      this.stats = [{}, {}, {}, {}, {}, {}, {}]
      await api2.getDiskofferingList().then(response => {
        if (response.data) {
          this.hddOfferingList = response.data.HDD
        } else {
          console.log('Error creating one time Cyber Cloud login!')
        }
      }).catch((error) => {
        console.log(error)
      })
      await this.getDeploymentData()
      await wpapi.getAllTagDataFromAccountVms(this.accountIdInput).then(response => {
        this.allVmTagInfo = response.data
        for (var deployment of this.k8sDeployments) {
          if (deployment.licenses[0] !== undefined) {
            this.allVmTagInfo.push(deployment.licenses[0])
          }
        }
      }).catch(error => {
        this.$notifyError(error)
        this.changeAccountDisplayLoading = false
      })
      // list all VMs
      var vmParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVirtualMachines', vmParams).then(json => {
        var count = 0
        this.noBillVmList = []
        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        var serverCPU = 0
        var clientCPU = 0
        var serverVMs = []
        var clientVMs = []
        var vms = json.listvirtualmachinesresponse.virtualmachine
        if (vms === undefined) {
          this.windowsServer = 0
          this.windowsClients = 0
          this.serverVMInfo = 0
          this.clientVMInfo = 0
        }
        this.allVms = vms
        if (json.listvirtualmachinesresponse.virtualmachine && vms.length > 0 && vms !== undefined) {
          for (var vmIndex = 0; vmIndex < vms.length; vmIndex++) {
            // get all VMs that have a "NoBill" Tag and save them in noBillVmList
            var vmFilter = this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id)
            if (vmFilter.length > 0) {
              if (vmFilter.filter(x => x.tagText === 'NoBill').length > 0) {
                this.noBillVmList.push(vms[vmIndex])
              }
            }
            // dont add cores to VMs with NoBill or Fixed Price Tags
            if (vms[vmIndex].state !== 'Destroyed' && vmFilter.length >= 1 && vmFilter.filter(x => x.tagType === 'NoBill').length === 0) {
              // vmTagInfo = VM where vm name === name of VM with the licenseTagInfo and tagType === OS
              var vmTagInfo = vmFilter.filter(x => x.tagType === 'OS')[0]
              if (vmTagInfo) {
                var discountCalculated = this.discountList.filter(x => x.usageName === vmTagInfo.tagText)[0].discountCalculated !== undefined ? this.discountList.filter(x => x.usageName === vmTagInfo.tagText)[0].discountCalculated : 0
                if (vmTagInfo.tagText.toLowerCase().indexOf('server') !== -1) {
                  serverCPU = serverCPU + vms[vmIndex].cpunumber
                  serverVMs.push([vms[vmIndex].name, vms[vmIndex].cpunumber, discountCalculated])
                } else if (vmTagInfo.tagText.toLowerCase().indexOf('server') === -1) {
                  clientCPU = clientCPU + vms[vmIndex].cpunumber
                  clientVMs.push([vms[vmIndex].name, vms[vmIndex].cpunumber, discountCalculated])
                }
              }
            }
          }
          this.windowsServer = serverCPU
          this.windowsClients = clientCPU
          this.serverVMInfo = serverVMs
          this.clientVMInfo = clientVMs
        }
        this.stats.splice(2, 1, { name: this.$t('label.total.vms'), count: count, icon: 'number-outlined', path: '/vm' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalvms-bg'] || '#ffffff'
      })
      await this.buildLicenseDetailTable()
      // list running VMs
      var vmParamsRunning = { state: 'Running', account: this.accountNameInput, domainid: this.domainIdInput }
      // vmParamsRunning = { state: 'Running', listall: true }
      api('listVirtualMachines', vmParamsRunning).then(json => {
        var count = 0
        var vms = json.listvirtualmachinesresponse.virtualmachine
        var runningCPUs = 0
        var runningRAM = 0
        // get CPU and RAM amount out of each running vm
        if (vms !== undefined) {
          for (var vmIndex = 0; vmIndex < vms.length; vmIndex++) {
            var vmFilter = this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id)
            // check if any of the running VMs has a "noBill" Tag - dont take their CPUs and RAM into account if yes.
            if (vmFilter.length > 0) {
              // license connected but ots not "NoBill"
              if (vmFilter.filter(x => x.tagText === 'NoBill' || x.tagType === 'Fixed Price').length === 0) {
                runningCPUs = runningCPUs + vms[vmIndex].cpunumber
                runningRAM = runningRAM + vms[vmIndex].memory
              }
            } else {
              // no connected licenses
              runningCPUs = runningCPUs + vms[vmIndex].cpunumber
              runningRAM = runningRAM + vms[vmIndex].memory
            }
          }
        }

        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        this.runningVMCPU = runningCPUs
        this.runningVMRam = runningRAM
        // var tileColor = this.$config.theme['@dashboard-tile-runningvms-bg'] || '#dfe9cc'
        this.stats.splice(0, 1, { name: this.$t('label.running'), count: count, icon: 'desktop-outlined', bgcolor: 'green', path: '/vm', query: { state: 'running', filter: 'running' } })
      })
      // list stopped VMs
      var vmParamsStopped = { state: 'Stopped', account: this.accountNameInput, domainid: this.domainIdInput }
      api('listVirtualMachines', vmParamsStopped).then(json => {
        var count = 0
        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        this.stats.splice(1, 1, { name: this.$t('label.stopped'), count: count, icon: 'poweroff-outlined', bgcolor: 'red', path: '/vm', query: { state: 'stopped', filter: 'stopped' } })
        // var tileColor = this.$config.theme['@dashboard-tile-stoppedvms-bg'] || '#edcbce'
      })
      // list Volumes
      var volumeParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      api('listVolumes', volumeParams).then(json => {
        var count = 0
        if (json && json.listvolumesresponse) {
          count = json.listvolumesresponse.count
        }
        var hdd = 0
        var ssd = 0
        var vms = json.listvolumesresponse.volume
        for (var vmIndex in vms) {
          var vmidInAllVmTagInfo = this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].virtualmachineid)
          // check if any of the volumes has VM with "noBill" Tag - dont take their DISK Size into account if yes.
          if (vmidInAllVmTagInfo.length > 0) {
            // license connected but its not "NoBill"
            if (vmidInAllVmTagInfo.filter(x => x.tagText === 'NoBill' || x.tagType === 'Fixed Price').length === 0) {
              if (vms[vmIndex].diskofferingid && this.hddOfferingList.some(params => params.uuid === vms[vmIndex].diskofferingid)) {
                hdd = hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
              } else {
                ssd = ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
              }
            } else {
              // vm, connected to this volume, that has licenses in general AND a noBill Tag
              var vmInNoBillVMList = this.noBillVmList.filter(x => x.id === vms[vmIndex].virtualmachineid)
              if (vmInNoBillVMList.length > 0) {
                if (vms[vmIndex].diskofferingid && this.hddOfferingList.some(params => params.uuid === vms[vmIndex].diskofferingid)) {
                  if (vmInNoBillVMList[0].hdd === undefined) {
                    vmInNoBillVMList[0].hdd = (vms[vmIndex].size / 1024 / 1024 / 1024)
                  } else {
                    vmInNoBillVMList[0].hdd = vmInNoBillVMList[0].hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
                  }
                } else {
                  if (vmInNoBillVMList[0].ssd === undefined) {
                    vmInNoBillVMList[0].ssd = (vms[vmIndex].size / 1024 / 1024 / 1024)
                  } else {
                    vmInNoBillVMList[0].ssd = vmInNoBillVMList[0].ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
                  }
                }
              }
            }
          } else {
            // no connected licenses
            if (vms[vmIndex].diskofferingid && this.hddOfferingList.some(params => params.uuid === vms[vmIndex].diskofferingid)) {
              hdd = hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
            } else {
              ssd = ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
            }
          }
        }
        this.hddData = hdd
        this.ssdData = ssd
        this.stats.splice(3, 1, { name: this.$t('label.total.volume'), count: count, icon: 'database-outlined', path: '/volume' })
      }).catch(error => {
        this.$notifyError(error)
        this.changeAccountDisplayLoading = false
      })
      // list Networks
      var networkParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listNetworks', networkParams).then(json => {
        var count = 0
        if (json && json.listnetworksresponse) {
          count = json.listnetworksresponse.count
        }
        this.stats.splice(4, 1, { name: this.$t('label.total.network'), count: count, icon: 'apartment-outlined', path: '/guestnetwork' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalnetworks-bg'] || '#ffffff'
      })
      // list publicIpAdresses
      await api('listPublicIpAddresses', { account: this.accountNameInput, domainid: this.domainIdInput, allocatedonly: true }).then(json => {
        var count = 0
        if (json && json.listpublicipaddressesresponse) {
          if (json.listpublicipaddressesresponse.count !== undefined) {
            count = json.listpublicipaddressesresponse.count
          } else { count = 0 }
          if (json.listpublicipaddressesresponse.publicipaddress !== undefined) {
            this.allocatedIps = json.listpublicipaddressesresponse.publicipaddress.length
          } else { this.allocatedIps = 0 }
        }
        this.stats.splice(5, 1, { name: this.$t('label.public.ip.addresses'), count: count, icon: 'environment-outlined', path: '/publicip' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalips-bg'] || '#ffffff'
      })
      // list VPCs
      var vpcParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVPCs', vpcParams).then(json => {
        var count = 0
        if (json && json.listvpcsresponse) {
          this.vpcs = json.listvpcsresponse.vpc
          count = json.listvpcsresponse.count
        }
        this.stats.splice(6, 1, { name: 'VPC´s', count: count, icon: 'deployment-unit-outlined', path: '/vpc' })
      })
      // logic to determine how many next generation firewalls (ngf) this account has (if there are more than one vpc!)
      const vpcs = this.vpcs
      this.enabledNgfs = 0
      for (var vpc in vpcs) {
        await wpapi.getNgfStatus(this.accountNameInput, vpcs[vpc].id).then(response => {
          if (response.data.filter(x => x.vpcuuid === vpcs[vpc].id)[0] !== undefined && response.data.filter(x => x.vpcuuid === vpcs[vpc].id)[0].status === true) {
            this.enabledNgfs += 1
          }
        }).catch(error => {
          this.$notifyError(error)
          this.changeAccountDisplayLoading = false
        })
      }
      // this.listEvents()
      this.metricsLoaded = true
    },
    /**
     * List all Events on the dashboard via API "listEvents"
     * @public
     */
    listEvents () {
      const params = {
        page: 1,
        pagesize: 6,
        listall: true
      }
      this.loading = true
      api('listEvents', params).then(json => {
        this.events = []
        this.loading = false
        if (json && json.listeventsresponse && json.listeventsresponse.event) {
          this.events = json.listeventsresponse.event
        }
      })
    },
    getEventColour (event) {
      if (event.level === 'ERROR') {
        return 'red'
      }
      if (event.state === 'Completed') {
        return 'green'
      }
      return 'blue'
    },
    onTabChange (key) {
      this.showAddAccount = false

      if (key !== 'Dashboard') {
        this.showAddAccount = true
      }
    },
    roundValue (input) {
      return (Math.round((input + Number.EPSILON) * 100) / 100).toFixed(2)
    },
    /**
     * This function regulates the behavior of (multi)collapsable rows
     * @param {String} collapseID ID of the table Cell that should be collapsed on click (Primary- and Secondary Storage, Licences, Windows- Server and Clients, etc.)
     */
    collapseRow (collapseID, collapseSpeed) {
      if (collapseID === 'licences') {
        if (this.licenceDetailsToggled === true) {
          this.licenceDetailsToggled = false
        } else {
          this.licenceDetailsToggled = true
        }
        $(`#${collapseID}.collapsable`).toggleClass('expand').nextUntil($('.endCollapse')).toggle(collapseSpeed)
      } else if (collapseID === 'noBill') {
        if (this.noBillToggled === true) {
          this.noBillToggled = false
        } else {
          this.noBillToggled = true
        }
        $(`#${collapseID}.collapsable`).toggleClass('expand').nextUntil($('.endCollapse')).toggle(collapseSpeed)
      } else if (collapseID === 'csrbs') {
        if (this.cssToggled === true) {
          this.cssToggled = false
        } else {
          this.cssToggled = true
        }
        $(`#${collapseID}.collapsable`).toggleClass('expand').nextUntil($('.endCollapse')).toggle(collapseSpeed)
      } else {
        $(`#${collapseID}.collapsable`).toggleClass('expand').nextUntil($('.endCollapse')).toggle(collapseSpeed)
      }
    }
  }
}
</script>

<style lang="less" scoped>
 .globalContainer {
    width: 100%;
  }

  .quotaStatementContainer {
    display: flex;
    justify-content: space-between;
  }
  .quotaStatement {
    width: 80%;
  }

  .metricsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .resourceList {
    width: 20%;
    font-size: 10px;
    border: 1px solid #ddd;
    max-height: 33.3em;
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
    background: #EEEEEE;
    padding: 20px;
    margin-right: 10px;
  }

  .resourceListLink:hover {
    background-color: #e6f7ff;
  }
  .licenseDetailsTable {
    //font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 100%;
  }
  .licenseDetailsTable td {
    padding: 7.3px;
    border: 1px solid #ddd;
    text-align: center;
  }
  .licenseDetailsTable tr {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .licenseDetailsTable tr:hover {background-color: #e6f7ff;}
  .licenseDetailsTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #dddddd;
    color: white;
    height: 25px;
  }
  .domainMetricsTable {
    //font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 80%;
    z-index: 2;
    max-height: 33.3em;
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
    background: #EEEEEE;
    padding: 20px;
    // margin-left: 30px;
  }

  .domainMetricsTable td {
    padding: 7.3px;
    z-index: 2;
  }
  .domainMetricsTable tr {
    border: 1px solid #ddd;
    padding: 8px;
    z-index: 2;
  }

  .domainMetricsTable tr:hover {background-color: #e6f7ff;}

  .domainMetricsTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #3D7CAF;
    color: white;
    height: 25px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
 .domainMetricsTable tr.collapsable {
   cursor: pointer;
   z-index: 2;
 }
 .collapsable .sign:after{
   content:"▼";
   color: #3D7CAF;
   display: inline-block;
 }
 .collapsable.expand .sign:after {
   color: #3D7CAF;
   content:"▲";
 }
 .costCalculator {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ddd;
    // float: right;
    margin-top: 15px;
 }
.sliderAndInput {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0 55px 0 55px;
}

.cpuSlider {
  width: 100%;
}

.licenceInputWrapper {
  display: flex;
  flex-direction: row;
}

.usageTypeColumn {
  width:30%;
}
.usageAmountColumn {
  text-align: right;
  width:18%;
}
.xColumn {
  text-align: center;
}
.equalColumn {
  width: 1%;
}
.tariffColumn {
  text-align: left;
  width:35%;
}
.singleCostColumn {
  text-align: right;
  width: 12%;
}

.inputField {
  width: 110px;
  margin-left: 5px;
}

.serverInputField {
  width: 110px;
  margin-left: 5px;
}

.strikeout {
  line-height: 1em;
  position: relative;
}

.strikeout::after {
  border-bottom: 0.2em solid rgba(255, 0, 0, 0.5);
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
}
.ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.loadingAnimation:after {
  content: '';
  animation: dots 1s steps(5, end) infinite;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.loadingAnimation:after {
  content: '';
  animation: dots 1s steps(5, end) infinite;
}

.accountDisplaySelection {
  width: 23em;
}

@keyframes dots {
  0%, 20% {
    content: '.'}
  40% {
    content: '..'}
  60% {
    content: '...'}
  80%, 100% {
    content: ''}
}
@media only screen and (max-width: 625px) {
  .inputField {
    min-width: 95px;
  }
  .cpuSlider {
    min-width: 3em;
  }
  .licenceInputWrapper {
    flex-direction: column;
  }
  .serverInputField {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 665px) {
  .rowDiscount {
    display: none;
  }
  .usageAmountColumn {
    width:35%;
  }
  .tariffColumn {
    width: 27%;
  }
  .usageTypeColumn {
    width: 40;
  }
}

@media only screen and (max-width: 910px) {
  .quotaStatementContainer {
    justify-content: center;
  }
  .usageAmountColumn {
  width:16%;
  }
  .usageTypeColumn {
    width: 31%;
  }
  .tableRow {
    font-size: calc(5px + 0.8vw);
  }
  .quotaStatement {
    width: 100%;
  }
  .xColumn {
    width: 1%;
  }
}
  :deep(.usage-dashboard) {

    &-chart-tile {
      margin-bottom: 12px;
    }

    &-chart-card {
      padding-top: 24px;
    }

    &-chart-card-inner {
      text-align: center;
    }

    &-footer {
       padding-top: 12px;
       padding-left: 3px;
       white-space: normal;
    }
  }

  @media (max-width: 1200px) {
    .ant-col-xl-8 {
      width: 100%;
    }
  }
</style>
